import React from 'react'

export default class IndexClientItem extends React.Component {
  constructor() {
    super()
  }

  renderClientLogo() {
    return <div dangerouslySetInnerHTML={{ __html: this.props.logo }} />
  }

  render() {
    let {isActive} = this.props

    return (
      <div
        data-position = {this.props.position}
        className     = {isActive ? 'featuredClientItem active' : 'featuredClientItem default'}
        style         = {{borderColor: isActive ? this.props.color : '#D9D9D9'}}
        onClick       = {this.props.onClick}
      >
        <div
          data-position = {this.props.position}
          className     = 'item-data'
        >
        </div>
        {this.props.logo ? this.renderClientLogo() : null}
      </div>
    )
  }
}
