import React from 'react'

export default function Platforms(props) {
  return (
    <div className='platformsBlocks-container'>
      <div className='platform-block large' id='web'>
        <div className='platformBlock-content'>
          <h2>Онлайн-медиа c&nbsp;авторскими материалами о&nbsp;технологиях и&nbsp;современном мире</h2>
          <div className='platformBlock-links'>
            <a target='_blank' rel='noreferrer' href='https://wylsa.com/'>
              Wylsa.com
            </a>
          </div>
        </div>
      </div>

      <div className='platform-block small' id='app'>
        <div className='platformBlock-content'>
          <h2>Мобильное приложение<br></br>с&nbsp;новыми форматами взаимодействия</h2>
          <div className='platformBlock-links'>
            <a target='_blank' rel='noreferrer' href='https://apps.apple.com/ru/app/wylsacom-media/id1481939376'>
              App Store
            </a>
            <a target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=com.wylsacom.media&hl=ru'>
              Google Play
            </a>
          </div>
        </div>
      </div>

      <div className='platform-block small' id='event'>
        <div className='platformBlock-content'>
          <h2>Придумываем спецпроекты и проводим онлайн-трансляции</h2>
        </div>
      </div>
    </div>
  )
}
