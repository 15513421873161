import React from 'react'

import YoutubeLarge from '../../assets/icons/YoutubeLarge.svg'

export default function YoutubeBadge(props) {
  return (
    <a
      target='blank'
      className='youtubeBadge'
      href='https://www.youtube.com/user/Wylsacom'
    >
      <img src={YoutubeLarge} alt='YoutubeLarge'/>
      <h2>{props.value}</h2>
    </a>
  )
}
