import React from 'react'

import YouTube     from 'react-youtube'
import VideoThumbnail from './VideoThumbnail.js'
import ProjectMeta from './ProjectMeta.js'

export default class IndexProjectItem extends React.Component {
  constructor() {
    super()

    this.handleVideoThumbnailClick = this.handleVideoThumbnailClick.bind(this)
  }

  handleVideoThumbnailClick() {
    this.props.onClick(this.props.videoURL.split('?v=')[1])
  }

  renderClientLogo() {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: this.props.client.logo }}
        className = 'projectItemLogo'
      />
    )
  }

  render() {
    const itemClass = this.props.isActive ? ' active' : ''

    return (
      <div className={`featuredProjectItem${itemClass}`}>
        <VideoThumbnail
          onClick = {this.handleVideoThumbnailClick}
          videoID = {this.props.videoURL.split('?v=')[1]}
        />

        <div className='featuredProjectItem-content'>
          {this.props.client.logo ? this.renderClientLogo() : null}

          <div className='featuredProjectItem-description'>
            <h3>{this.props.description}</h3>
          </div>

          <ProjectMeta
            userpic    = {this.props.author.userpic.publicURL}
            authorName = {this.props.author.name}
            views      = {this.props.views}
          />          
        </div>
      </div>
    )
  }
}
