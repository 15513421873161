import React from 'react'
import { graphql } from 'gatsby'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

import MetaData              from '../components/atoms/MetaData.js'
import YoutubeBadge          from '../components/atoms/YoutubeBadge.js'
import ModalPlayer           from '../components/atoms/ModalPlayer.js'
import MenuBar               from '../components/MenuBar'
import RichBlock             from '../components/RichBlock'
import IndexFeaturedAuthors  from '../components/IndexFeaturedAuthors'
import IndexFeaturedProjects from '../components/IndexFeaturedProjects'
import Platforms             from '../components/Platforms'
import Footer                from '../components/Footer'

import PromoVideo from '../assets/promo.mp4'

export default class Home extends React.Component {
  constructor() {
    super()

    this.toggleModalPlayer = this.toggleModalPlayer.bind(this)
    this.togglePageScroll = this.togglePageScroll.bind(this)
    this.targetElement = null

    this.state = {
      modalPlayer: {
        active: false,
        videoID: null
      },
      scroll: true
    }
  }

  componentDidMount() {
    this.targetElement = document.querySelector('.page')
  }

  componentDidUpdate(prevProps, prevState) {
    // Disable Page Scroll
    if(prevState.scroll != this.state.scroll) {
      if(this.state.scroll) {
        enableBodyScroll(this.targetElement)
      } else {
        disableBodyScroll(this.targetElement)
      }
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  toggleModalPlayer(data) {
    if(this.state.modalPlayer.active) {
      this.setState({
        ...this.state,
        modalPlayer: {
          active: false,
          videoID: null
        },
        scroll: true
      })
    } else {
      this.setState({
        ...this.state,
        modalPlayer: {
          active: true,
          videoID: data
        },
        scroll: false
      })
    }
  }

  togglePageScroll() {
    this.setState({
      ...this.state,
      scroll: !this.state.scroll
    })
  }

  renderModalPlayer() {
    return (
      <ModalPlayer
        videoID = {this.state.modalPlayer.videoID}
        onClick = {this.toggleModalPlayer}
      />
    )
  }

  render() {
    let authorsData  = this.props.data.allAuthorsJson.nodes
    let projectsData = this.props.data.allProjectsJson.nodes

    return (
      <div className='app-wrapper'>
        <MetaData
          title       = 'Wylsacom Media'
          description = 'Мы развиваем крупнейшее медиа о технологиях в рунете.'
        />

        {this.state.modalPlayer.active ? this.renderModalPlayer() : null}

        <MenuBar
          items  = {MenuBarItems}
          action = {this.togglePageScroll}
        />

        <RichBlock
          id   = 'main'
          text = {['Мы', <span key='span'>, Wylsacom Media,</span>,  ' развиваем крупнейшее медиа о технологиях в рунете.']}
        />

        <YoutubeBadge value={'10 700 000 подписчиков'} />

        <div className='video-container'>
          <video autoPlay muted loop playsInline>
            <source src={PromoVideo} type='video/mp4' />
          </video>
        </div>

        <RichBlock
          id   = 'team'
          text = 'Мы — это команда техногиков. Вместе мы делаем контент чаще и лучше.'
        />

        <IndexFeaturedAuthors authors={authorsData}/>

        <RichBlock
          id   = 'projects'
          text = 'Работаем с&nbsp;крупными брендами и&nbsp;рассказываем об&nbsp;их&nbsp;продуктах нашей аудитории.'
        />

        <IndexFeaturedProjects
          action   = {this.toggleModalPlayer}
          projects = {projectsData}
        />

        <RichBlock
          id   = 'platforms'
          text = 'Выходим за рамки ютуба и развиваем свои цифровые продукты.'
        />

        <Platforms />

        <Footer />
      </div>
    )
  }
}

export const IndexData = graphql`
  {
    allProjectsJson(filter: {featured: {eq: true}}, limit: 6) {
      nodes {
        description
        videoURL
        views
        client {
          name
          color
          logo
        }
        author {
          name
          userpic {
            publicURL
          }
        }
      }
    },
    allAuthorsJson(filter: {featured: {eq: true}}, sort: {fields: position}, limit: 5) {
      nodes {
        id
        name
        socialAccount
        socialAccountURL
        subscribers
        userpic {
          publicURL
        }
      }
    }
  }
`

const MenuBarItems = [
  {
    title: 'лица',
    to: '#team',
    external: false
  },
  {
    title: 'кейсы',
    to: '#projects',
    external: false
  },
  {
    title: 'платформы',
    to: '#platforms',
    external: false
  },
  {
    title: 'связаться',
    to: '#contact',
    external: false
  },
  {
    title: 'цены',
    to: 'https://docs.google.com/spreadsheets/d/1fOcAeps0CaBygkR9bmPinpkZ2LBYEzO_PJkYkfMKLrg',
    external: true
  }
]
