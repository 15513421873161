import React from 'react'
import { Link } from 'gatsby'

import IndexClientItem  from './atoms/IndexClientItem.js'
import IndexProjectItem from './atoms/IndexProjectItem.js'

import PlusMore from '../assets/icons/PlusMore.svg'

export default class IndexFeaturedProjects extends React.Component {
  constructor() {
    super()

    this.toggleClientsItem = this.toggleClientsItem.bind(this)
    this.handleClientItemClick = this.handleClientItemClick.bind(this)
    this.handleVideoThumbnailClick = this.handleVideoThumbnailClick.bind(this)
    this.enableAutoToggle = this.enableAutoToggle.bind(this)
    this.disableAutoToggle = this.disableAutoToggle.bind(this)

    this.autoToggle = null

    this.state = {
      activeCard: 0,
      auto: true
    }
  }

  componentDidMount() {
    this.enableAutoToggle()
  }

  componentDidUpdate() {
    if(!this.state.auto) {
      this.disableAutoToggle()
    }
  }

  componentWillUnmount() {
    this.disableAutoToggle()
  }

  enableAutoToggle() {
    this.autoToggle = setInterval(this.toggleClientsItem, 6000)
  }

  disableAutoToggle() {
    clearTimeout(this.autoToggle)
  }

  toggleClientsItem() {
    let limit = this.props.projects.length - 1

    if(this.state.activeCard < limit) {
      this.setState({
        activeCard: this.state.activeCard + 1
      })
    } else {
      this.setState({
        activeCard: 0
      })
    }
  }

  handleClientItemClick(e) {
    e.preventDefault()
    let cardPosition = parseInt(e.target.dataset.position)

    if(this.state.auto) {
      this.setState({
        auto: false,
        activeCard: cardPosition
      })
    } else {
      this.setState({
        ...this.state,
        activeCard: cardPosition
      })
    }
  }

  handleVideoThumbnailClick(data) {
    this.props.action(data)
    this.disableAutoToggle()
  }

  renderClientsItems() {
    let clientsElements = []
    const {activeCard} = this.state
    const {projects} = this.props
    const action = this.handleClientItemClick

    projects.map(function(project, i) {
      clientsElements.push(
        <IndexClientItem
          {...project.client}
          isActive = { i == activeCard ? true : false }
          position = { i }
          onClick  = { action }
          key      = { i }
        />
      )
    })

    return(
      clientsElements
    )
  }

  renderProjectItem() {
    let projectsElements = []
    const {activeCard} = this.state
    const {projects} = this.props
    const action = this.handleVideoThumbnailClick

    projects.map(function(project, i) {
      projectsElements.push(
        <IndexProjectItem
          {...project}
          isActive = { i == activeCard ? true : false }
          position = { i }
          onClick  = { action }
          key      = { i }
        />
      )
    })

    return (
      projectsElements
    )
  }

  render() {
    return (
      <div className='featuredProjects-container'>
        <div className='featuredClientsList'>
          {this.renderClientsItems()}

          <Link
            to='/projects/'
            className='featuredClientsMoreLink'
          >
            <img src={PlusMore} alt='See More'/>
            больше<br/>кейсов
          </Link>
        </div>

        <div className='featuredProjectItems-container'>
          {this.renderProjectItem()}

          <Link
            to='/projects/'
            className='featuredClientsMoreLink mobile'
          >
            <img src={PlusMore} alt='See More'/>
            <span>больше кейсов</span>
          </Link>
        </div>
      </div>
    )
  }
}
