import React    from 'react'
import { Link } from 'gatsby'

import IndexAuthorItem from './atoms/IndexAuthorItem.js'

export default class IndexFeaturedAuthors extends React.Component {
  constructor() {
    super()
  }

  renderAuthorsItems() {
    let authorsElements = []

    const { authors } = this.props

    authors.map(function(author, i) {
      authorsElements.push(
        <IndexAuthorItem
          {...author}
          key = { i }
        />
      )

      return null
    })

    return(
      authorsElements
    )
  }

  render() {
    return (
      <div className='featuredAuthors-container'>
        {this.renderAuthorsItems()}

        <Link
          to='/team/'
          className='featuredAuthorItem all'
        >
          <div className='featuredAuthorItemImage'></div>
          <h4>Вся<br></br>команда</h4>
        </Link>
      </div>
    )
  }
}
